import { CTA }                      from '@open-law/open-law-shared'
import { graphql }                  from 'gatsby'
import React, { FunctionComponent } from 'react'
import EventsPost                   from '../components/events/events-post'
import { OpenLawEvent }             from '../components/EventsList'
import Layout                       from '../containers/layout'

export const query = graphql`
    query EventPostTemplateQuery($id: String!) {
        event: sanityEvent(id: {eq: $id}) {
            id
            mainImage {
                asset {
                    url
                }
            }
            body: _rawBody(resolveReferences: {maxDepth: 10})
            endDateTime
            duration
            excerpt: _rawExcerpt(resolveReferences: {maxDepth: 10})
            _id
            startDateTime(formatString: "DD/MM/YYYY")
            registrationUrl
            publishedAt(formatString: "DD/MM/YYYY")
            platform
            tags
            title
            format
        }
    }
`

interface EventsPostTemplateProps {
    data: {
        event: OpenLawEvent
    }
}

const EventsPostTemplate: FunctionComponent<EventsPostTemplateProps> = (props) => {
    const {data, errors} = props
    const event          = data && data.event
    return (
        <Layout textWhite={true}>
            {/*{
             post && (
             <SEO
             title={post.title || 'Untitled'}
             description={toPlainText(post._rawExcerpt
             )
             }
             image={post.mainImage}
             />
             )
             }*/}
            {
                event && <EventsPost event={event}/>
            }
            <section>
                <CTA cta={{
                    layout       : 'textLeftButtonRight',
                    leadText     : 'Get started with JADE today',
                    secondaryText: `Use Australia's best legal research tool to improve your efficiency and results.`,
                    button       : {
                        buttonText         : 'Join Us',
                        url                : 'https://jade.io/registration',
                        backgroundColourOne: 'white',
                        backgroundColourTwo: 'blue'
                    }

                }}/>
            </section>
        </Layout>
    )
}

export default EventsPostTemplate
